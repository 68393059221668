import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const QuotesPage = () => (
  <Layout>
    <SEO title="Δικαιολογίες" />
    <h2>Δικαιολογίες / Quotes</h2>
    <ol className="quotes">
      <li>Δεν θα περάσει κανείς από εκεί.</li>
      <li>Σιγά μην ενοχλεί.</li>
      <li>Και εσένα τι σε νοιάζει ρε; Τροχονόμος είσαι;</li>
      <li>Όπου θέλω θα το αφήσω.</li>
      <li>Εδώ είναι Ελλάδα φίλε.</li>
      <li>Τι κοιτάς ρε; Θέλεις να σου σπάσω τα μούτρα;</li>
      <li>Για 5 λεπτά σταμάτησα μόνο.</li>
      <li>Μα που να το αφήσω, δεν βλέπεις τι γίνεται;</li>
      <li>Φύγε ρε καημένε από εδώ μη φας καμία.</li>
    </ol>
  </Layout>
)

export default QuotesPage
